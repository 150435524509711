<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-card>
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            enctype="multipart/form-data"
        >
          <div class="row">
            <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
            <div class="col-md-6">
              <b-form-group label="Certification Title" label-for="certification_title">
                <validation-provider
                    #default="{ errors }"
                    name="certification_title"
                    rules="required"
                >
                  <b-form-input
                      id="certification_title"
                      v-model="from.certification_title"
                      :state="errors.length > 0 ? false : null"
                      name="certification_title"
                      placeholder="Enter Certification Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group label="Meta Title" label-for="meta_title">
                    <validation-provider
                        #default="{ errors }"
                        name="meta_title"
                        rules="required"
                    >
                      <b-form-input
                          id="meta_title"
                          v-model="from.meta_title"
                          :state="errors.length > 0 ? false : null"
                          name="meta_title"
                          placeholder="Enter Meta Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group label="Meta Description" label-for="meta_description">
                    <validation-provider
                        #default="{ errors }"
                        name="meta_description"
                        rules="required"
                    >
                      <Input
                          id="meta_description"
                          v-model="from.meta_description"
                          :state="errors.length > 0 ? false : null"
                          name="meta_description"
                          placeholder="Enter Meta Description"
                          type="textarea"
                          :autosize="{ minRows: 8, maxRows: 8 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                  class="row"
                  v-for="(point,index) in from.certification_point" :key="index"
                  v-if="from.certification_point.length"
              >
                <div class="col-lg-10 col-md-9">
                  <b-form-group label="Certification Description Point" label-for="certification_description">
                    <Input
                        name="certification_point"
                        type="text"
                        v-model="point.data"
                        id="certification_point"
                        placeholder="Enter Certification Description Point"
                    />
                  </b-form-group>
                </div>
                <div class="col-lg-2 col-md-3 mt-1">
                  <button
                      style="padding: 6px"
                      class="btn btn-primary w-100"
                      @click="addDetails()"
                      v-if="index+1==from.certification_point.length"
                  >
                    <feather-icon icon="PlusCircleIcon" size="22" />
                  </button>
                  <button
                      style="padding: 6px"
                      class="btn btn-danger w-100"
                      v-else
                      @click="removeDetails(index)"
                  >
                    <feather-icon icon="DeleteIcon" size="22" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- submit buttons -->
          <b-button type="submit" variant="primary" @click="validationForm">
            Update
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  vSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      from: {
        certification_title: "",
        certification_point:[],
        meta_title : '',
        meta_description : '',
      },
      required,
      email,
    };
  },
  mounted(){
    this.addDetails(),
        this.getResult()
  },
  methods: {
    addDetails() {
      this.from.certification_point.push({
        data: null,
      });
      console.log(this.from.certification_point)
    },
    async getResult() {
      const certification = await this.callApi("get", "/app/cms/certification/page");
      if(certification){
        this.from.certification_title = certification?.data?.certification?.title;
        this.from.certification_point = certification?.data?.certification?.description_array;
        this.from.meta_title  = certification?.data?.certification?.meta_title;
        this.from.meta_description = certification?.data?.certification?.meta_description;
      }
    },
    removeDetails(index) {
      this.from.certification_point.splice(index,1);
    },
    Update() {
      this.from.certification_title = this.from.certification_title ;
      this.from.meta_title = this.from.meta_title ;
      this.from.meta_description = this.from.meta_description ;
      if (this.from.certification_point) {
        this.from.certification_point =
            this.from.certification_point;
      } else {
        this.from.certification_point.push({ data: null });
      }
      axios
          .post(
              "/app/cms/certification/page" ,
              this.from
          )
          .then((res) => {
            this.s(res.data.message);
          })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect
  },
};
</script>

<style></style>
  