<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-card>
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            enctype="multipart/form-data"
        >
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <!-- email -->
                <b-form-group label="Title" label-for="title">
                  <validation-provider
                      #default="{ errors }"
                      name="title"
                      rules="required"
                  >
                    <b-form-input
                        id="title"
                        v-model="from.title"
                        :state="errors.length > 0 ? false : null"
                        name="title"
                        placeholder="Enter Your  Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <!-- email -->
                <b-form-group label="Description" label-for="sort_description">
                  <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                  >
                    <b-form-input
                        id="description"
                        v-model="from.description"
                        :state="errors.length > 0 ? false : null"
                        name="title"
                        placeholder="Enter Your  Description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group label="Button Name" label-for="btn_1">
                      <validation-provider
                          #default="{ errors }"
                          name="btn_name"
                          rules="required"
                      >
                        <b-form-input
                            id="btn_1"
                            v-model="from.btn_name"
                            :state="errors.length > 0 ? false : null"
                            name="btn_name"
                            placeholder="Enter Your Button Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                  #default="{ errors }"
                  name="meta_title"
                  rules="required"
              >
                <b-form-input
                    id="meta_title"
                    v-model="from.meta_title"
                    :state="errors.length > 0 ? false : null"
                    name="meta_title"
                    placeholder="Enter Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="col-md-12">
              <b-form-group label="Meta Description" label-for="meta_description">
                <validation-provider
                    #default="{ errors }"
                    name="meta_description"
                    rules="required"
                >
                  <Input
                      id="meta_description"
                      v-model="from.meta_description"
                      :state="errors.length > 0 ? false : null"
                      name="meta_description"
                      placeholder="Enter Meta Description"
                      type="textarea"
                      :autosize="{ minRows: 8, maxRows: 8 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <!-- submit buttons -->
          <b-button type="submit" variant="primary" @click="validationForm">
            Update
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  vSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      from: {
        title: "",
        description:"",
        btn_name:'',
        meta_title : '',
        meta_description : '',
      },
      required,
      email,
    };
  },
  mounted(){
    this.getResult();
  },
  methods: {
    async getResult() {
      const certification = await this.callApi("get", "/app/cms/certification/find/page");
      if(certification){
        this.from.title = certification?.data?.certification_find?.title;
        this.from.description = certification?.data?.certification_find?.description;
        this.from.btn_name  = certification?.data?.certification_find?.btn_name;
        this.from.meta_title = certification?.data?.certification_find?.meta_title;
        this.from.meta_description = certification?.data?.certification_find?.meta_description;
      }
    },

    removeDetails(index) {
      this.form.certification_point.splice(index,1);
    },
    Update() {
      this.from.title = this.from.title ;
      this.from.description = this.from.description ;
      this.from.btn_name = this.from.btn_name ;
      this.from.meta_title = this.from.meta_title ;
      this.from.meta_description = this.from.meta_description ;

      axios
          .post(
              "/app/cms/certification/find/page" ,
              this.from
          )
          .then((res) => {
            this.s(res.data.message);
          })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect
  },
};
</script>

<style></style>
